import React,{useState,useEffect} from 'react'

import {Link,Redirect,useHistory} from "react-router-dom"
import Base from '../core/Base'

//import aboutphoto from "../assets/images/portfolio/02.jpg";
import Aos from 'aos'
import 'aos/dist/aos.css'

import organogram from "../assets/images/organogram.jpeg";
import Fade from 'react-reveal/Fade';

import Carousel from 'react-bootstrap/Carousel'  
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";
const About=()=> {
    let history=useHistory();
    useEffect(() => {
      Aos.init({duration:2000})
        const unlisten = history.listen(() => {
          window.scrollTo(0, 0);
        });
        return () => {
          unlisten();
        }
      }, []);
    const AboutScreen=()=>{
        return(
          <body>
 
 <div>
 
 
   <section data-aos="fade-right" id="blog" className="blog" style={{marginTop:"100px"}}>
     
      <div className="container">
      <div className="col-lg-12">         
          <article class="entry entry-single" style={{backgroundColor:'#f9f9f9',textAlign:'justify'}}>
          <div class="page-hero d-flex align-items-center justify-content-center">
              <div>
                   <h2 style={{color:"#000",fontSize:"19px",textAlign:"justify",fontWeight:"750"}}>
                   Sangli Shikshan Sanstha's

                        </h2></div>
              </div>
          <div class="page-hero d-flex align-items-center justify-content-center">
              <div>
                   <h2 style={{color:"#000",fontSize:"19px",textAlign:"justify",fontWeight:"750"}}>
                 
Smt. PUTALABEN SHAH COLLEGE OF EDUCATION, SANGLI

                        </h2></div>
              </div>
              
              <div class="page-hero d-flex align-items-center justify-content-center" style={{marginTop:"30px"}}>
              <div> <h2 style={{color:"#000",fontSize:"19px",textAlign:"justify",fontWeight:"750"}}>Motto</h2></div>
              </div>
              <div 
              class="page-hero d-flex align-items-center justify-content-center">
 <p style={{color:"#000",fontSize:"18px",marginTop:"10px"}}>
 "Ohm Tejasvi Navadhitmastu" 
 </p>
             

              </div>
              


      {/* <p style={{color:"#000",fontSize:"20px",marginTop:"10px"}}>
      To meet with the growing demand of the competent teachers, the Sangli Shikshan Sanstha started this 
      college in 1961 as College of Education. Later it was renamed as 
      “Smt. Putalaben Shah College of Education” in 1979 which was inaugurated by then Central Home 
      Minister Shri. Yashwantrao Chavan. The college is affiliated to Shivaji University, Kolhapur. 
      Since its inception, the college has been striving hard to cater the needs of Teacher Education 
      and helping the students to become dedicated, committed, intellectually well developed, socially 
      concerned, morally upright and spiritually oriented teachers. At present the college runs B.ed course of Shivaji University, Kolhapur and B.ed(Distance Mode) course of Yashwantrao Chavan 
      Maharashtra Open University, Nashik. The college is reaccredited by NAAC with ‘B’ grade.
      All the faculty members are Ph.D holders. Three faculties are recognized guides for M.Phil and Ph.D of Shivaji University.
      Faculty members are seriously engaged in research and publication activities. Four out of six faculties have published their reference books.
     Research papers are being published in reputed journals.
     We are planning to establish a research centre in our college.
      </p> */}


    
      {/* <div class="page-hero d-flex align-items-center justify-content-center">
              <div> <h2 style={{color:"#000",fontSize:"19px",textAlign:"justify",fontWeight:"750"}}>RESEARCH ACTIVITIES</h2></div>
              </div> */}
      {/* <p style={{color:"#000",fontSize:"20px",marginTop:"10px",fontWeight:"650"}}>
     <li></li>
    
     
     <li>We are planning to establish a research centre in our college.</li>
      </p> */}
     
      <div class="page-hero d-flex align-items-center justify-content-center" style={{marginTop:"30px"}}>
              <div> <h2 style={{color:"#000",fontSize:"19px",textAlign:"justify",fontWeight:"750"}}>OUR VISION</h2></div>
              </div>
              {/* <p style={{color:"#000",fontSize:"20px",marginTop:"10px",fontWeight:"650"}}>
     <li>To provide a better value proposition in terms of educational quality, cost and service.</li>
     <li>To provide a secular, discipline and caring environment for all learners.</li>
     <li>To be in the forfront of education to satisfy the local, national and global needs. </li>
     <li>To shape, sensitize and inculate in the prospective teachers a desire for excellence combined with right attitudes, values and ideologies.</li>
     <li>To acchieve academic excellence through hard work, critical thinking and effective decision making</li>
     <li>To facilitate learning among their pupils through appropriate skills and methodologies and to exercise responsible leadership and render selfless service to the community </li>
      </p> */}
       <p style={{color:"#000",fontSize:"20px",marginTop:"10px"}}>
     <li>The Smt. Putalaben Shah College of Education as a Center of Excellence in teacher education envisions to be a leader in the formation of competent, dedicated, committed, conscientious, intellectually well developed, socially concerned, morally upright and spiritually oriented teachers who can respond to and benefit from the demands and challenges of a fast changing local and global learning environment.
</li>
    
      </p>
      <div class="page-hero d-flex align-items-center justify-content-center">
              <div> <h2 style={{color:"#000",fontSize:"19px",textAlign:"justify",fontWeight:"750"}}>OUR MISSION</h2></div>
              </div>
      <p style={{color:"#000",fontSize:"20px",marginTop:"10px"}}>
      <li> To provide a better value proposition in terms of educational quality, cost and service.</li>
      <li> To provide a secular, discipline and caring environment for all learners.</li>
      <li> To generate a new work culture for improving the practice of education through teaching, research and extension.</li>
      <li> To achieve and sustain excellence in teacher development through experimentation and innovation.</li>
      <li> To be in the forefront of the education to satisfy the local, national and global needs.</li>
      <li> To shape, sensitize and inculcate in the prospective teachers a desire for excellence combined with right attitudes, values and ideologies. </li>
      <li> To facilitate learning among their pupils through appropriate skills and methodologies and to exercise responsible leadership and render selfless service to the community.</li>

      </p>
      <div class="page-hero d-flex align-items-center justify-content-center">
              <div> <h2 style={{color:"#000",fontSize:"19px",textAlign:"justify",fontWeight:"750"}}>Values</h2></div>
              </div>
      <p style={{color:"#000",fontSize:"20px",marginTop:"10px"}}>
      <li> Contributing to national development.</li>
      <li> Inculcation of a value system among students.</li>
      <li> Fostering global competencies among the students.</li>
      <li> Promoting the use of Technology.</li>
      <li> Quest for Excellence.</li>
     
      </p>
      <div class="page-hero d-flex align-items-center justify-content-center">
              <div> <h2 style={{color:"#000",fontSize:"19px",textAlign:"justify",fontWeight:"750"}}>OBJECTIVES</h2></div>
              </div>
      <p style={{color:"#000",fontSize:"20px",marginTop:"10px"}}>
      <li>To make available competent and trained teachers for the Secondary and Higher secondary Schools in rural and urban areas run by the Sanstha as well as other management.
</li>
      
      <li>To create competent and commitment oriented teachers.</li>
     
      <li> To provide able, professional and skilled teachers to schools to attain desired social change.</li>
    
     <li>To impart knowledge and training of latest technology for teaching, learning, and evaluation.
</li>
      
      <li>To sensitize the would be teachers about emerging issues such as human rights, environmental awareness, population growth, gender equality, scientific temper and rational thinking.
</li>
     
      <li> To promote capabilities for inculcating national values and goals as enshrined in the constitution of India. 
</li>
    
      <li>To educate the student-teacher as an effective communicator, designer, facilitator, and user of learning resources.
</li>
     
      <li> To enable the student teachers in experimentation and in using innovative practices and effective modes of curriculum transaction. 
</li>
     
      <li> To stimulate social and national integration.
</li>
     
     <li> To provide in-service education to teachers, teacher educators and the inspecting staff concerned with primary, secondary and higher secondary education.
</li>
      </p>
      <div class="page-hero d-flex align-items-center justify-content-center">
              <div> <h2 style={{color:"#000",fontSize:"19px",textAlign:"justify",fontWeight:"750"}}>Organogram</h2></div>
              </div>
      <div class="page-hero d-flex align-items-center justify-content-center" >
              <div class="bookcover-container">
              <SimpleReactLightbox>
                           <SRLWrapper>
              <img src={organogram} alt=""  class="img-fluid"/>
              </SRLWrapper>
              </SimpleReactLightbox>
</div>
</div>
      </article>
          </div>
      </div>
     
    </section>
   
      
   
</div>
                </body>
               

                
            )
    }

    return(
        <Base>
        {AboutScreen()}
        </Base>
    )

}

export default About
import React,{useState,useEffect,useRef} from 'react'

import {Link,Redirect,useHistory} from "react-router-dom"
import Base from '../core/Base'
import backgroundOne from "../assets/images/bgHome.JPG";

import { Scrollbar as BaseScrollbar } from "smooth-scrollbar/scrollbar";
import Aos from 'aos'
import 'aos/dist/aos.css'
import { AnimationWrapper } from 'react-hover-animation'
import Fade from 'react-reveal/Fade';
import { APIMEDIA } from '../backend'
import Carousel from 'react-bootstrap/Carousel'  
import man1 from "../assets/images/founders/man1.jpg";
import man2 from "../assets/images/founders/man2.jpg";
import man3 from "../assets/images/founders/man3.jpg";
import man4 from "../assets/images/founders/man4.jpg";

 import '../assets/css/style.css'
 import CountUp from 'react-countup';
 import { API } from '../backend'
 import axios from "axios";
 const width = window.innerWidth
const Home=()=> {

  //const [NewsAndAnnoncement, setNews] = useState("No any news or annoncement found at this moment");
  const [allnews, setAllNews] = useState([]);
  const [pos,setPos] = useState(0);   
  const [run, setRun] = useState(true);

  const scrollEff = () => {
   
    if(run) setPos(p=>p<width? p+1: -width);        
 
}

  const getAllNews = () => {     
    axios
    .get(
        `${API}/getAllNews`,
    
    )
    .then((response) => {
      console.log(response);
      if(response.status===200)
        {
            if(response.data.status==true)
            {      
             
              console.log(response.data.news)
           
            setAllNews(response.data.news);
          
            }
              
        }
        else
        {
            alert("error")
          
        }
    })
    .catch((error) => {
      console.log(error.message);
    });

    };
  const scrollbar = useRef<BaseScrollbar | null>(null);
  let history=useHistory();
  useEffect(() => {
   
   // getAllNews()
    const tm = setTimeout(scrollEff, 10);
    return () => clearTimeout(tm);
   
    }, [pos]);
    const onMouseEnter = (e) => {
  
      setRun(false);
  }
  
  const onMouseLeave = (e) => {
     
      setRun(true);
      setPos(pos+1); 
  }
  

  const styles = {
      position: "relative", 
      fontSize: "1em",
      right: pos + "px"
  };



    const homescreen=()=>{
        return(
         
          <body>
 
<header class="masthead">
  <div class="container h-100">
    <div class="row h-100 align-items-center">
      <div class="col-12 text-center">
        <h1 style={{color:"red",fontWeight:1000}}>Welcome to Smt. Putlaben Shah College of Education, Sangli. </h1>
        {/* <p class="lead">Lets Begin Your Teaching Journey With Us</p> */}
      </div>
    </div>
  </div>
 
</header>
 


                <main id="main">
                  
             
    <section id="blog" className="blog">
   
    <div className="container-fluid">
                 <div className="row">
                   <div className="col-lg-12">
                 <article class="entry entry-single" style={{backgroundColor:'#f4f4f4',padding:'0px 0px 0px 0px '}}>
               <h1 style={styles} 
            onMouseEnter={onMouseEnter} 
            onMouseLeave={onMouseLeave} 
        >
         {
             allnews.slice(0).reverse().map(
              (info,index) =>
              {
              
                var count = Object.keys(info).length;
           
                if(count>0)
                {
                 
                  if(info.attachment_file_name!='')
                  {
                  
                return(
                  <span style={{textAlign:'left',fontSize:"18px"}}>
               <a href={APIMEDIA+'/filesdata/'+info.attachment_file_name} target="_blank">{index>0? <span> |</span>:''} {info.news}</a> {info.flashnew=="true"?<span className="blink_me" style={{fontSize:"15px",color:'red',marginLeft:"5px"}}>new</span>:''} 
                  </span>                  
                  )
                  
                }
                else
                {
                 
                  return(
                   
                  <span style={{textAlign:'left',fontSize:"18px"}}>
                    
                    {index>0? <span> |</span>:''} {info.news}{info.flashnew=="true"?<label className="blink_me" style={{fontSize:"15px",color:'red',marginLeft:"5px"}}> new </label>:''}
                     </span>  
                    
                     
                  )   
                 
                }
                }                              
              
              }

             )
         }
          </h1>
       </article>
       </div>
        </div>
               </div>
      <div className="container">

        <div className="row">
        
         
          <div className="col-lg-12">         
          <article class="entry entry-single" style={{backgroundColor:'#f9f9f9'}}>
            
             <div className="row">
              
<div className="col-md-12">
<div style={{color:"black",fontSize:"20px",textAlign:'justify'}}>

            <p>
            To meet with the growing demand of the competent teachers, the Sangli Shikshan Sanstha 
            started this college in 1961 as College of Education. 
            Later it was renamed as “Smt. Putalaben Shah College of Education” in 1979 which was inaugurated 
            by then Central Home Minister Shri. Yashwantrao Chavan. The college is affiliated to Shivaji 
            University, Kolhapur. Since its inception, the college has been striving hard to cater the needs 
            of Teacher Education and helping the students to become dedicated, committed, intellectually 
            well developed, socially concerned, morally upright and spiritually oriented teachers. 
            At present the college runs B.Ed. course of Shivaji University, Kolhapur and B.Ed.(Distance Mode) 
            course of Yashwantrao Chavan Maharashtra Open University, Nashik. The college is reaccredited by 
            NAAC with ‘B’ grade.
            </p>
          
         
            </div>
         
            </div>
            
            </div>
            <div style={{textAlign:'center'}}>
      <h2 style={{color:"#000",fontSize:"20px",fontWeight:"600"}}>Founders of Sangli Shikshan Sanstha</h2>
     
    </div>
    <div className="row">
            <div class="page-hero d-flex col-md-3" >
            <div class="imageHolder">
              <img src={man1} alt=""  class="img-fluid"/>
              <div class="caption">ब्रह्मीभूत गुरुनाथ गोविंद तोरो</div>
              </div>
            
</div>
<div class="page-hero d-flex col-md-3" >
<div class="imageHolder">
              <img src={man2} alt=""  class="img-fluid"/>
              <div class="caption">कै. राजाराम रावजी चिकोडीकर</div>
</div>
</div>
<div class="page-hero d-flex col-md-3" >
<div class="imageHolder">
              <img src={man3} alt=""  class="img-fluid"/>
              <div class="caption">कै. विनायक सदाशिव जोशी</div>
              </div>
</div>
<div class="page-hero d-flex col-md-3" >
<div class="imageHolder">
              <img src={man4} alt=""  class="img-fluid"/>
              <div class="caption">कै. धोंडो विठ्ठल सखदेव</div>
              </div>
</div>
</div>
            </article>


          </div>
         
        </div>
       
      </div>
    </section>

  

 
 <div className="container carousel-bottomsection-margin" style={{borderTop:"1px solid silver",borderBottom:"1px solid silver",marginTop:"50px"}}>


                <div className="row">
                <div className="col-lg-3 " style={{borderRight:"1px solid silver",backgroundColor:'white'}}>
                  <p style={{textAlign:'center',fontSize:"20px",color:"#1c5c93",marginTop:"10px"}}>News & Annoncements</p>
                  <div class="microsoft containermerquee">
                  <div className="row marquee">
                  <p>
             {
             
             allnews.slice(0).reverse().map(
                (info) =>
                {
                 // setNews('')
                  console.log(info);
                  var count = Object.keys(info).length;
              
                  if(count>0)
                  {
                   
                    if(info.attachment_file_name!='')
                    {
                    
                  return(
                    <p style={{textAlign:'left',fontSize:"20px"}}>
                 <a href={APIMEDIA+'/filesdata/'+info.attachment_file_name} target="_blank">{info.news}</a> {info.flashnew=="true"?<span className="blink_me" style={{fontSize:"15px",color:'red',marginLeft:"5px"}}>new</span>:''} 
                    </p>                  
                    )
                    
                  }
                  else
                  {
                   
                    return(
                     
                    <p style={{textAlign:'left',fontSize:"20px"}}>
                      
                   {info.news}{info.flashnew=="true"?<label className="blink_me" style={{fontSize:"15px",color:'red',marginLeft:"5px"}}> new </label>:''}
                       </p>  
                      
                       
                    )   
                   
                  }
                  }
                 
                  else
                  {
                    return(
                      <p style={{textAlign:'left',fontSize:"20px"}}>
                  alert("hi")
                      </p>                  
                      )
                  }
                
                }

               )
             }
              {/* <p style={{textAlign:'left',fontSize:"20px"}}>
                {NewsAndAnnoncement}
                    </p>    */}
            
             
            </p>
          </div>
           </div>             
                  </div>
                <div className="col-lg-3" style={{borderRight:"1px solid silver",borderLeft:"1px solid silver",backgroundColor:'white'}}>
              
            
          
            <p style={{textAlign:'center',fontSize:"42px",color:"#1c5c93"}}><i class="fa fa-map-marker" aria-hidden="true"></i></p>
            <p style={{textAlign:'center',fontSize:"20px"}}>
             Address
            </p>
            <p style={{textAlign:'center',fontSize:"20px"}}>
            Smt.Putlaben Shah College of Education, Rajwada, Paga Chowk, Sangli, 416416 (Maharashtra)
            </p>
            
           
                </div>
                <div className="col-lg-3" style={{borderRight:"1px solid silver",backgroundColor:'white'}}>
              
            
             
              <p style={{textAlign:'center',fontSize:"42px",color:"#1c5c93"}}><i class="fa fa-phone " aria-hidden="true"></i></p>
            <p style={{textAlign:'center',fontSize:"20px"}}>
           Phone Number
            </p>
            <p style={{textAlign:'center',fontSize:"20px"}}>
            0233-2970178
            </p>
           
                  </div>
                  <div className="col-lg-3" style={{borderRight:"1px solid silver",backgroundColor:'white'}}>
              
            
             
              <p style={{textAlign:'center',fontSize:"42px",color:"#1c5c93"}}><i class="fa fa-envelope" aria-hidden="true"></i></p>
            <p style={{textAlign:'center',fontSize:"20px"}}>
            Email
            </p>
           
            <p style={{textAlign:'center',fontSize:"20px"}}>
            san_spsc@rediffmail.com
            </p>                        
                  </div>
                </div>
                </div>
 

  </main>
  
                </body>
               

                
            )
    }

    return(
        <Base>
        {homescreen()}
        </Base>
    )

}

export default Home
import React,{useState,useEffect} from 'react'

import {Link,Redirect,useHistory} from "react-router-dom"
import Base from '../core/Base'

import axios from "axios";

import { API } from '../backend'
import Aos from 'aos'
import Fade from 'react-reveal/Fade';
import { APIMEDIA } from '../backend'
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";
const StaffDetails=()=> {
    let history=useHistory();
    const [contentTeaching,setContentTeaching]=useState([])
    const [contentNonTeaching,setContentNonTeaching]=useState([])
    const [staff_type,setStaffType]=useState("")

    const [staff_name,setStaffName]=useState("")
    const [designation,setDesignation]=useState("")
   
    const [qualification,setQualification]=useState("")
    const [joing_date,setJoiningDate]=useState("")
    const [pay_scale,setPayScale]=useState("")

    const getTeachingStaffData = () => {    
    
        axios
        .get(
            `${API}/getteachingstaffdetails`,        
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          if(response.status===200)
            {
                if(response.data.status==true)
                {      
                 
                  console.log(response.data.staffdetails)
              
                setContentTeaching(response.data.staffdetails);
               
                }
                  
            }
            else
            {
                alert("error")
              
            }
        })
        .catch((error) => {
          console.log(error.message);
        });
  
        }
        const getNonTeachingStaffData = () => {    
      
          axios
          .get(
              `${API}/getnonteachingstaffdetails`,        
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log(response);
            if(response.status===200)
              {
                  if(response.data.status==true)
                  {      
                   
                    console.log(response.data.staffdetails)
                  
                  setContentNonTeaching(response.data.staffdetails);
                
                  }
                    
              }
              else
              {
                  alert("error")
                
              }
          })
          .catch((error) => {
            console.log(error.message);
          });
    
          };



    useEffect(() => {
        getTeachingStaffData();
          getNonTeachingStaffData()
        Aos.init({duration:2000})
          const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
          });
          return () => {
            unlisten();
          }
        
        }, []);
    const StaffDetailsScreen=()=>{
        return(
          <body>
 
                <main id="main">
             
                <section id="blog" className="blog" style={{marginTop:"100px",marginBottom:"400px"}}>
      <div className="container">
          <div className="row">
              <div className="col-md-12">
      <div class="alert alert-danger" role="alert" style={{marginTop:"10px"}}>
 Teaching Staff
</div>
      <table class="table table-bordered">
              <thead>
            
              <th>Photo</th>
                  <th>Name</th>
                  <th>Designation </th>
                  <th>Qualification </th>
                  <th>Joining Date </th>
                  <th>Scale Of Pay </th>
                  <th>Biodata</th>
                 
              </thead>
                <tbody>
            
        {
            contentTeaching.map(
              (info) =>
              {
                var count = Object.keys(info).length;
                console.log(count);
                if(count>0)
                {
                   
                  
                return(
              
                      <tr>
                      
                          <td style={{color:'red'}}> 
                          <SimpleReactLightbox>
                           <SRLWrapper>
                        <img src={APIMEDIA+'/photogallery/'+info.photo_file_name} alt={info.staff_name} class="avatar" height="150" width="150"/>
                      
                        </SRLWrapper>   
                        </SimpleReactLightbox>
                         </td>
                          <td>{info.staff_name}</td>
                          <td>{info.designation}</td>
                          <td>{info.qualification}</td>
                          <td>{info.joing_date}</td>
                          <td>{info.pay_scale}</td>
                          <td style={{color:'red'}}> <a href={APIMEDIA+'/photogallery/'+info.biodata_file_name} target="_blank">  <i class="fa fa-file" aria-hidden="true"></i> </a> </td>
                          
                      </tr>
                     
                  )
                
               
                }
              
              }
            )
        }
                    
              {/* {preload} */}
                    
                </tbody>
            </table>
            </div>
            </div>



            <div className="row">
                <div className="col-md-12">
      <div class="alert alert-danger" role="alert" style={{marginTop:"10px"}}>
 Non Teaching Staff
</div>
      <table class="table table-bordered">
              <thead>
             
              <th>Photo</th>
                  <th>Name</th>
                  <th>Designation </th>
                  <th>Qualification </th>
                  <th>Joining Date </th>
                  <th>Scale Of Pay </th>
                  <th>Biodata</th>
                 
              </thead>
                <tbody>
            
        {
            contentNonTeaching.map(
              (info) =>
              {
                var count = Object.keys(info).length;
                console.log(count);
                if(count>0)
                {
                   
                  
                return(
              
                      <tr>
                       
                          <td style={{color:'red'}}>  
                          <SimpleReactLightbox>
                           <SRLWrapper>
                          <img src={APIMEDIA+'/photogallery/'+info.photo_file_name} alt="Avatar" class="avatar" height="150" width="150"/> 
                          </SRLWrapper>
                          </SimpleReactLightbox>
                          </td>
                          <td>{info.staff_name}</td>
                          <td>{info.designation}</td>
                          <td>{info.qualification}</td>
                          <td>{info.joing_date}</td>
                          <td>{info.pay_scale}</td>
                          <td style={{color:'red'}}> <a href={APIMEDIA+'/photogallery/'+info.biodata_file_name} target="_blank">  <i class="fa fa-file" aria-hidden="true"></i> </a> </td>
                          
                      </tr>
                     
                  )
                
               
                }
              
              }
            )
        }
                    
              {/* {preload} */}
                    
                </tbody>
            </table>
      </div>
      </div>

      </div>
     
    </section>

    

  
    <section id="clients" className="clients section-bg">
      <div className="container">

     
      </div>
    </section>

 
  
   
  <a href="#" id="toTop" class="back-to-top"><i class="icofont-simple-up"></i></a>

  </main>
                </body>
               

                
            )
    }

    return(
        <Base>
        {StaffDetailsScreen()}
        </Base>
    )

}

export default StaffDetails
import React,{useState,useEffect} from 'react'

import {Link,Redirect} from "react-router-dom"
import Base from '../core/Base'

import axios from "axios";
import { API } from '../backend'
const Links=()=> {
    const [content,setContent]=useState([])
    const LinksScreen=()=>{
        return(
          <body>
 
                <main id="main" style={{marginBottom:"400px"}}>
             
    <section id="services" className="services" style={{marginTop:"100px"}}>
      <div className="container">

      
 <div class="section-title"> <h2 style={{color:"#FF0000",fontSize:"20px",textAlign:"justify"}}>Useful Links</h2></div>
        <div className="row">
          
        <table class="table table-bordered">
    <thead>
      <tr>
        <th>Sr.No</th>
        <th>Links</th>
        
      </tr>
    </thead>
    <tbody>
    <tr>
        <td>1</td>
        <td><a href="http://www.unishivaji.ac.in/" target="_blank">Shivaji University, Kolhapur</a></td>
        
      </tr>
      <tr>
        <td>2</td>
        <td><a href="https://ncte.gov.in/website/index.aspx" target="_blank">NCTE</a></td>
        
      </tr>
      <tr>
        <td>3</td>
        <td><a href="http://jdhekop.blogspot.com/" target="_blank">Joint Director</a></td>
        
      </tr>
      <tr>
        <td>4</td>
        <td><a href="https://www.dhepune.gov.in/" target="_blank">Directorate of Higher Education,Pune,Maharashtra</a></td>
        
      </tr>
      <tr>
        <td>5</td>
        <td><a href="https://www.education.gov.in/en" target="_blank">Ministry of Human Resource Development, Government of India</a></td>
        
      </tr>
      <tr>
        <td>6</td>
        <td><a href="https://www.maharashtra.gov.in/1125/Home" target="_blank">Government of Maharashtra</a></td>
        
      </tr>
      <tr>
        <td>7</td>
        <td><a href="http://www.naac.gov.in/index.php/en/" target="_blank">NAAC/RUSA</a></td>
        
      </tr>
      <tr>
        <td>8</td>
        <td><a href="https://www.ugc.ac.in/" target="_blank">University Grant Commission</a></td>
        
      </tr>
      <tr>
        <td>9</td>
        <td><a href="https://www.dhepune.gov.in/mpua-2016" target="_blank">Maharashtra Public University Act</a></td>
        
      </tr>
      <tr>
        <td>10</td>
        <td><a href="http://sppudocs.unipune.ac.in/sites/circulars/DocLib/Forms/AllItems.aspx" target="_blank">SPPU</a></td>
        
      </tr>
      <tr>
        <td>11</td>
        <td><a href="http://moodlelms.spsbedsangli.org/" target="_blank">Moodle LMS</a></td>
        
      </tr>
    
    </tbody>
  </table>
          
        </div>

      </div>
    </section>

    

  
    <section id="clients" className="clients section-bg">
      <div className="container">

     
      </div>
    </section>

 
  
   
  <a href="#" id="toTop" class="back-to-top"><i class="icofont-simple-up"></i></a>

  </main>
                </body>
               

                
            )
    }

    return(
        <Base>
        {LinksScreen()}
        </Base>
    )

}

export default Links
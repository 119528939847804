import React from 'react'
import {withRouter,Link as MenuLink} from "react-router-dom";

import Logo from '../assets/images/logo.png'
import {Navbar,Nav} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'

const currentTab = (history, path) => {
    if (history.location.pathname === path) {
    
      return { color: "#FFFF00" };
    }

  };

  const currentTabAdmission = (history, path) => {
    
    if (
      history.location.pathname==="/admission" || 
      history.location.pathname==="/admissionform" ||
      history.location.pathname==="/admissionreceipt"
   
      ) {
      
      return { color: "#FFFF00" } ;
    }
  };

  const activateTabAbout = (history, path) => {
    
    if (
      path==="/about" || 
      path==="/objectives" || 
      path==="/towardsdevelopment" ||
      path==="/ourprides" 
      
      ) {
      
      return { color: "#FFFF00" } ;
    }
  };
  const activateTabAcademics = (history, path) => {
    
    if (
    
      path==="/courses" ||
      path==="/admissionmeritlist" ||
      path==="/academiccalendar" ||
      path==="/feestructure" ||
      path==="/studentdetails"||
      path==="/committees"
      ) {
       
      return { color: "#FFFF00" } ;
    }
  };

  const activateTabOther = (history, path) => {
    
    if (
    
      path==="/facilities" ||
     
      path==="/rti" ||
     
      path==="/links" ||
      path==="/ugc"||
      path==="/collegedocuments"


      ) {
       
      return { color: "#FFFF00" } ;
    }
  };
  const activateTabGallery = (history, path) => {
    
    if (
     
      path==="/videogallery" ||
      path==="/photogallery"
      ) {
      
      return { color: "#FFFF00" } ;
    }
  };

  const Menu = ({ history }) => 
    (
     
      <header id="header" className="fixed-top ">
    <div className="container-fluid d-flex align-items-center">

     <div className="logo">
     <img src={Logo}  style={{marginRight:"10px"}} />
         <MenuLink  to="/">S.P.S. College of Education
      
       </MenuLink>
       </div> 
     
     
      
   
      <nav className="nav-menu d-none d-lg-block" >
     
     
        <ul>
       
         
          <li><MenuLink style={currentTab(history,"/")}  to="/">Home </MenuLink></li>

          <li className="drop-down"><a href="#" style={activateTabAbout(history,history.location.pathname)}>About</a>
            <ul>
              <li><MenuLink style={currentTab(history,"/about")}  to="/about">Introduction</MenuLink></li>
              {/* <li><MenuLink style={currentTab(history,"/dnaconfidence")}  to="/principaldesk">Principal Desk</MenuLink></li> */}
              {/* <li><MenuLink style={currentTab(history,"/objectives")}  to="/objectives">Objectives</MenuLink></li> */}
              <li><MenuLink style={currentTab(history,"/towardsdevelopment")}  to="/towardsdevelopment">Towards Development</MenuLink></li>
              <li><MenuLink style={currentTab(history,"/ourprides")}  to="/ourprides">Our Prides</MenuLink></li>
             
              
            </ul>
          </li>
          <li className="drop-down" ><a href="#" style={activateTabAcademics(history,history.location.pathname)}>Academics</a>
            <ul>
              <li><MenuLink style={currentTab(history,"/courses")} to="/courses">Courses</MenuLink></li>
              <li><MenuLink style={currentTab(history,"/admissionmeritlist")}  to="/admissionmeritlist">Admission</MenuLink></li>              
              <li><MenuLink style={currentTab(history,"/feestructure")}  to="/feestructure">Fee Structure</MenuLink></li>
              <li><MenuLink style={currentTab(history,"/studentdetails")}  to="/studentdetails">Student Details</MenuLink></li>
              <li><MenuLink style={currentTab(history,"/committees")}  to="/committees">Committees</MenuLink></li>
              
             
            </ul>
          </li>
          <li><MenuLink style={currentTab(history,"/staffdetails")}  to="/staffdetails">Staff </MenuLink></li>
          {/* <li className="drop-down" ><a href="#" style={activateTabStaff(history,history.location.pathname)}>Staff</a>
            <ul>
              <li><MenuLink style={currentTab(history,"/teachingstaff")} to="/teachingstaff">Teaching Staff</MenuLink></li>
              <li><MenuLink style={currentTab(history,"/admissionmeritlist")}  to="/admissionmeritlist">Non Teaching Staff</MenuLink></li>
             
            </ul>
          </li> */}
     
     <li><MenuLink style={currentTab(history,"/gallery")}  to="/gallery">Gallery </MenuLink></li>
     <li className="drop-down" ><a href="#" style={activateTabOther(history,history.location.pathname)}>Other</a>
            <ul>
              <li><MenuLink style={currentTab(history,"/facilities")} to="/facilities">Facilities</MenuLink></li>
              
              <li><MenuLink style={currentTab(history,"/rti")}  to="/rti">RTI</MenuLink></li>
             
              <li><MenuLink style={currentTab(history,"/ugc")}  to="/ugc">UGC/RUSA</MenuLink></li>
              <li><MenuLink style={currentTab(history,"/collegedocuments")}  to="/collegedocuments">College Documents</MenuLink></li>
              <li><MenuLink style={currentTab(history,"/links")}  to="/links">Links</MenuLink></li>
             
             
            </ul>
          </li>
     <li><MenuLink style={currentTab(history,"/naac")}  to="/naac">NAAC </MenuLink></li>

     <li><MenuLink style={currentTab(history,"/iqac")}  to="/iqac">IQAC </MenuLink></li>
     
     <li><MenuLink style={currentTab(history,"/ncte")}  to="/ncte">NCTE</MenuLink></li>
     <li className="drop-down" ><a href="#" >Library</a>
            <ul>
              
              <li><a href="https://sites.google.com/view/spscollegesangli/home" target="_blank">Visit Library Website</a></li>
             
             
            </ul>
          </li>
          <li className="drop-down" ><a href="#" >Feedback</a>
            <ul>
              
              <li><MenuLink style={currentTab(history,"/feedbackforms")}  to="/feedbackforms">Feedback Forms</MenuLink></li>
              <li><MenuLink style={currentTab(history,"/feedbackanalysis")}  to="/feedbackanalysis">Feedback Analisys</MenuLink></li>
             
             
            </ul>
          </li>

          {/* <li><MenuLink style={currentTab(history,"/feedbacks")}  to="/feedbacks">Success Stories</MenuLink></li>
          <li><MenuLink style={currentTabAdmission(history)}  to="/admission">Admission</MenuLink></li>
          <li><MenuLink style={currentTab(history,"/books")}  to="/books">Recommended Books</MenuLink></li> */}
           

            <li><MenuLink style={currentTab(history,"/contactus")}  to="/contactus">Contact </MenuLink></li>
          
          
       

        </ul>
       
      </nav>
      
    </div>
  </header>
    
      
    )

export default withRouter(Menu);
import React,{useState,useEffect} from 'react'

import {Link,Redirect} from "react-router-dom"
import Base from '../core/Base'
import { APIMEDIA } from '../backend'
import axios from "axios";
import { API } from '../backend'
const RTI=()=> {
    const [content,setContent]=useState([])
    const getRTI = () => {            
        var option_name="other_RTI"
        axios
        .post(
            `${API}/getotheroptions`,
          JSON.stringify({
          
            option_name:option_name
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          if(response.status===200)
            {
                if(response.data.status==true)
                {      
                 
                  console.log(response.data.otheroptions)
                //   var iqacDetails= response.data.iqac
                  setContent(response.data.otheroptions);
                //   setSectionName(response.data.iqac[0].section_name)
                }
                  
            }
            else
            {
                alert("error")
              
            }
        })
        .catch((error) => {
          console.log(error.message);
        });
  
        };


        useEffect(() => {
            getRTI();
          }, []);

    const RTIScreen=()=>{
        return(
          <body>
 
                <main id="main" style={{marginBottom:"400px"}}>
             
    <section id="services" className="services" style={{marginTop:"100px"}}>
      <div className="container">

      
 <div class="section-title"> <h2 style={{color:"#FF0000",fontSize:"20px",textAlign:"justify"}}>RTI</h2></div>
        <div className="row">
          
        <table class="table table-bordered">
              
              <tbody>
          
      {
          content.sort((a, b) => parseFloat(b.yeartosort) - parseFloat(a.yeartosort)).map(
            (info) =>
            {
              var count = Object.keys(info).length;
              console.log(count);
              if(count>0)
              {
              return(
            
                    <tr>
                      
                        <td style={{color:'red'}}> <a href={APIMEDIA+'/filesdata/'+info.file_url_name} target="_blank">  {info.file_caption} </a> </td>
                        {/* <td>{info.year}</td> */}
                        
                    </tr>
                   
                )
              }
            
            }
          )
      }
                  
            {/* {preload} */}
                  
              </tbody>
          </table>
          
        </div>

      </div>
    </section>

    

  
    <section id="clients" className="clients section-bg">
      <div className="container">

     
      </div>
    </section>

 
  
   
  <a href="#" id="toTop" class="back-to-top"><i class="icofont-simple-up"></i></a>

  </main>
                </body>
               

                
            )
    }

    return(
        <Base>
        {RTIScreen()}
        </Base>
    )

}

export default RTI
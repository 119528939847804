import React,{useState,useEffect} from 'react'

import {Link,Redirect,useHistory} from "react-router-dom"
import Base from '../core/Base'
import axios from "axios";
import { API } from '../backend'
//import aboutphoto from "../assets/images/portfolio/02.jpg";
import Aos from 'aos'
import 'aos/dist/aos.css'
const OurPride=()=> {
    let history=useHistory();
    const [allprides, setAllPrides] = useState([]);

    const preload = () => {
        axios({
            method: 'get',
            url: `${API}/getAllPrides`,
           
          }).then(res=>{
              console.log(res)
              if(res.status===200)
              {
                  if(res.data.status!==true)
                  {
                      return alert('something went wrong')
                   
                  }
                  setAllPrides(res.data.pride);
               
              }
              else
              {
                console.log("error")
              }
          });
      };
    useEffect(() => {
        preload()
      Aos.init({duration:2000})
        const unlisten = history.listen(() => {
          window.scrollTo(0, 0);
        });
        return () => {
          unlisten();
        }
      }, []);
    const OurPrideScreen=()=>{
        return(
         
   <section data-aos="fade-right" id="blog" className="blog" style={{marginTop:"100px",marginBottom:"400px"}}>
     
      <div className="container">
      <div className="col-lg-12">         
          <article class="entry entry-single" style={{backgroundColor:'#f9f9f9',textAlign:'justify'}}>
          <div class="section-title"> <h2 style={{color:"#FF0000",fontSize:"20px",textAlign:"justify"}}>Our Prides</h2></div>
          <table class="table table-bordered">
              <thead>
                  <tr>
                  <th>Sr.No	</th>
                      <th>Student Name</th>
                      <th>Year</th>
                      <th>Achievement</th> 
                  </tr>
              </thead>

              <tbody>
          
      {
          allprides.map(
            (info, i) =>
            {
              var count = Object.keys(info).length;
             
             
               var sr_no=i+1;            
              if(count>0)
              {
              return(
            
                    <tr>
                       
                        <td>{sr_no}</td>
                        <td style={{color:'red'}}>  {info.student_name} </td>
                        <td>{info.year}</td>
                        <td>{info.achievement}</td>
                        
                    </tr>
                   
                )
              }
            
            }
          )
      }
                  
              </tbody>
          </table>
    
      </article>
          </div>
      </div>
     
    </section>
   
      
   

                
               

                
            )
    }

    return(
        <Base>
        {OurPrideScreen()}
        </Base>
    )

}

export default OurPride
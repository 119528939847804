import React,{useState,useEffect} from 'react'

import {Link,Redirect,useHistory} from "react-router-dom"
import Base from '../core/Base'

//import aboutphoto from "../assets/images/portfolio/02.jpg";
import Aos from 'aos'
import 'aos/dist/aos.css'




import Fade from 'react-reveal/Fade';

import Carousel from 'react-bootstrap/Carousel'  

const TowardsDevelopment=()=> {
    let history=useHistory();
    useEffect(() => {
      Aos.init({duration:2000})
        const unlisten = history.listen(() => {
          window.scrollTo(0, 0);
        });
        return () => {
          unlisten();
        }
      }, []);
    const TowardsDevelopmentScreen=()=>{
        return(
          <body>
 
 <div>
 
 
   <section data-aos="fade-right" id="blog" className="blog" style={{marginTop:"100px"}}>
     
      <div className="container">
      <div className="col-lg-12">         
          <article class="entry entry-single" style={{backgroundColor:'#f9f9f9',textAlign:'justify'}}>
          <div class="page-hero d-flex align-items-center justify-content-center">
              <div> <h2 style={{color:"#000",fontSize:"19px",textAlign:"justify",fontWeight:"750"}}>Towards Development</h2></div>
              </div>
      <p style={{color:"#000",fontSize:"20px",marginTop:"10px",fontWeight:"650"}}>
     <li> College maintains high level of transparency in academic and administrative work.</li>
      </p>
      <p style={{color:"#000",fontSize:"20px",fontWeight:"650"}}>
      <li>Digitization of library and establishment of Institutional Repository (I.R.)</li>
      </p>
      <p style={{color:"#000",fontSize:"20px",fontWeight:"650"}}>
      <li>Developed library website and library blog.</li>
      </p>
      <p style={{color:"#000",fontSize:"20px",fontWeight:"650"}}>
      <li>Online database N-list.</li>
      </p>
      <p style={{color:"#000",fontSize:"20px",fontWeight:"650"}}>
      <li>Various activities conducted by different Subject Clubs.</li>
      </p>
      <p style={{color:"#000",fontSize:"20px",fontWeight:"650"}}>
      <li>Different committees to look after the works minutely and maintain quality.</li>
      </p>
      <p style={{color:"#000",fontSize:"20px",fontWeight:"650"}}>
     <li>Remedial Teaching Programme for weaker students.</li>
      </p>
      <p style={{color:"#000",fontSize:"20px",fontWeight:"650"}}>
      <li> Social Awareness activities through Street Plays, social projects, participating in the rallies etc.</li>
      </p>
      <p style={{color:"#000",fontSize:"20px",fontWeight:"650"}}>
      <li>Involvement of students and faculties in research activities.</li>
      </p>
      <p style={{color:"#000",fontSize:"20px",fontWeight:"650"}}>
      <li>Innovative teaching-learning activities are followed.</li>
      </p>

    
      </article>
          </div>
      </div>
     
    </section>
   
      
   
</div>
                </body>
               

                
            )
    }

    return(
        <Base>
        {TowardsDevelopmentScreen()}
        </Base>
    )

}

export default TowardsDevelopment
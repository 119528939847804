import React, { useState, useEffect } from 'react';
import{BrowserRouter,Switch,Route, Router, HashRouter} from "react-router-dom"
import Home from './main/Home.js'
import Course from './main/Course.js'

import $ from 'jquery';
//import './assets/vendor/bootstrap/css/bootstrap.min.css'
import './assets/vendor/icofont/icofont.min.css'
import './assets/vendor/boxicons/css/boxicons.min.css'
import './assets/vendor/animate.css/animate.min.css'
import './assets/vendor/remixicon/remixicon.css'
import './assets/vendor/venobox/venobox.css'
import './assets/vendor/owl.carousel/assets/owl.carousel.min.css'
import './assets/css/style.css'
import About from './main/About.js';
import Objectives from './main/Objectives.js';
import TowardsDevelopment from './main/TowardsDevelopment.js';
import OurPride from './main/OurPride.js';
import AdmissionMeritList from './main/AdmissionMeritList.js';
import AcademicCalendar from './main/AcademicCalendar.js';
import FeeStructure from './main/FeeStructure.js';
import StudentDetails from './main/StudentDetails.js';

import Facilities from './main/Facilities.js';
import Committees from './main/Committees.js';
import RTI from './main/RTI.js';
import NCTE from './main/NCTE.js';
import UGC from './main/UGC.js';
import IQAC from './main/IQAC.js';
import ContactUs from './main/ContactUs.js';
import PhotoGallery from './main/PhotoGallery.js';
import CollegeDocuments from './main/CollegeDocuments.js';
import Links from './main/Links.js';
import StaffDetails from './main/StaffDetails.js';
import Photos from './main/Photos.js';
import NAAC from './main/NAAC.js';
import FeedbackForms from './main/FeedbackForms.js';

import FeedbackAnalysis from './main/FeedbackAnalysis.js';

const Routes=()=> {
    useEffect(() => {
        
            if ($('.nav-menu').length) {
              var $mobile_nav = $('.nav-menu').clone().prop({
                class: 'mobile-nav d-lg-none'
              });
              $('body').append($mobile_nav);
              $('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>');
              $('body').append('<div class="mobile-nav-overly"></div>');
          
              $(document).on('click', '.mobile-nav-toggle', function(e) {
                $('body').toggleClass('mobile-nav-active');
                $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
                $('.mobile-nav-overly').toggle();
              });
          
              $(document).on('click', '.mobile-nav .drop-down > a', function(e) {
                e.preventDefault();
                $(this).next().slideToggle(300);
                $(this).parent().toggleClass('active');
              });
          
              $(document).click(function(e) {
                var container = $(".mobile-nav, .mobile-nav-toggle");
                if (!container.is(e.target) && container.has(e.target).length === 0) {
                  if ($('body').hasClass('mobile-nav-active')) {
                    $('body').removeClass('mobile-nav-active');
                    $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
                    $('.mobile-nav-overly').fadeOut();
                  }
                }
              });
            } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
              $(".mobile-nav, .mobile-nav-toggle").hide();
            }
        
        
            $(window).scroll(function() {
              if ($(this).scrollTop() > 100) {
                $('#header').addClass('header-scrolled');
              } else {
                $('#header').removeClass('header-scrolled');
              }
            });
          
            if ($(window).scrollTop() > 100) {
              $('#header').addClass('header-scrolled');
            }
        
          $(window).scroll(function() {
            if ($(this).scrollTop() > 100) {
              $('#toTop').fadeIn('slow');
            } else {
              $('#toTop').fadeOut('slow');
            }
          });
        
          $("#toTop").click(function() {
            $("#myscrollingelement").animate({
              scrollTop: 10
            }, 1000);
          });
        


           });
    return (
     
    
      <BrowserRouter>
      <Switch>
        
      <Route path="/" exact component={Home}/>

      <Route path="/about" exact component={About}/>      
          <Route path="/objectives" exact component={Objectives}/>
          <Route path="/towardsdevelopment" exact component={TowardsDevelopment}/>
          <Route path="/ourprides" exact component={OurPride}/>

          <Route path="/courses" exact component={Course}/>
          <Route path="/admissionmeritlist" exact component={AdmissionMeritList}/>
          <Route path="/academiccalendar" exact component={AcademicCalendar}/>
          <Route path="/feestructure" exact component={FeeStructure}/>
          <Route path="/studentdetails" exact component={StudentDetails}/>

          <Route path="/staffdetails" exact component={StaffDetails}/>

          <Route path="/facilities" exact component={Facilities}/>
          <Route path="/committees" exact component={Committees}/>
          <Route path="/rti" exact component={RTI}/>
          <Route path="/ncte" exact component={NCTE}/>
          <Route path="/ugc" exact component={UGC}/>
          <Route path="/iqac" exact component={IQAC}/>
          <Route path="/naac" exact component={NAAC}/>

          <Route path="/collegedocuments" exact component={CollegeDocuments}/>
          <Route path="/links" exact component={Links}/>

          <Route path="/contactus" exact component={ContactUs}/>
          <Route path="/gallery" exact component={PhotoGallery}/>
          <Route path="/feedbackforms" exact component={FeedbackForms}/>
          <Route path="/feedbackanalysis" exact component={FeedbackAnalysis}/>

          {/* <Route path="/photos:GalleryId?/:ProgramId?" exact component={Photos}/> */}
          {/* <Route exact path={["/photos/:id", "/photos"]} name="name" component={Photos}/>  */}
         
          {/* <Route path="photos(/:name)" component={Photos} /> */}
          {/* <Route path={["/photos/:id", "/photos"]} component={Photos} /> */}
          {/* path="/reset_token=:token" */}
          <Route path="/photos/:GalleryId&:ProgramId+" component={Photos} />

          {/* <Route path="/photos/" component={Photos} /> */}

          
         
      </Switch>
        </BrowserRouter>
        
    
     
    )
}
export default Routes;
import React,{useState,useEffect} from 'react'

import {Link,Redirect,useHistory} from "react-router-dom"
import Base from '../core/Base'
import axios from "axios";
import { API } from '../backend'
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";
import { Formik, Form, Field, ErrorMessage} from "formik";
import { APIMEDIA } from '../backend'
import * as Yup from "yup";
const Photos=({ match, location })=> {
    const [content, setAllContent] = useState([]);
    const [programName, setProgramName] = useState("");
    const [programYear, setProgramYear] = useState("");
    const [galleryLocalStorage, setGalleryLocalStorage] = useState([]);
    const [year,setyear]=useState([])
    const getAllPhotos = () => {
        axios
        .get(
            `${API}/getAllPhotos`,              
        )
        .then((response) => {
          console.log(response);
          localStorage.setItem('gallery', JSON.stringify(response.data));
          if(response.status===200)
            {
                var PhotoGallery = JSON.parse(localStorage.getItem('gallery'))
                console.log(PhotoGallery)
                setGalleryLocalStorage(PhotoGallery.photos)
                const params = new URLSearchParams(window.location.search)
               // console.log(params);
               console.log(match.params)
               const galleryId_value = match.params.GalleryId.substring(match.params.GalleryId.indexOf('=') + 1);
               const programId_value = match.params.ProgramId.substring(match.params.ProgramId.indexOf('=') + 1);
            //    var galleryId= parseInt( match.params.GalleryId)
            //    var programId= parseInt(match.params.ProgramId)

                // var galleryId= parseInt( params.get('GalleryId'))
                // var programId= parseInt( params.get('ProgramId'))
                var galleryId= parseInt( galleryId_value)
                var programId= parseInt( programId_value)
               console.log(galleryId)
               console.log(programId)
        console.log(PhotoGallery.photos);
               for(var i = 0; i < PhotoGallery.photos.length; i++)
        {
            if(PhotoGallery.photos[i].gallery_id ==galleryId)
            {
                console.log("program length", PhotoGallery.photos[i].program.length);
                for(var j=0;j<PhotoGallery.photos[i].program.length;j++)
               { 
                   console.log(PhotoGallery.photos[i].program[j].program_id);
                   if(PhotoGallery.photos[i].program[j].program_id==programId)
                {
                    console.log(PhotoGallery.photos[i].program[j].files);
                    // console.log(PhotoGallery.photos[i]);
                     setProgramName(PhotoGallery.photos[i].program[j].program_name)
                     setProgramYear(PhotoGallery.photos[i].year)
                    setAllContent(PhotoGallery.photos[i].program[j].files)
                    console.log("found");
                    return 0;
                }
            }
              
            }
           
        }               
            }
    
        })
        };
      

    let history=useHistory();
    useEffect(() => {
        getAllPhotos()
       
        const unlisten = history.listen(() => {
          window.scrollTo(0, 0);
        });
        return () => {
          unlisten();
        }
      }, []);
    const PhotoGalleryScreen=()=>{
     
        return(
          <body>
 
 <main id="main"> 
    <section id="blog" class="blog" style={{marginTop:"100px",marginBottom:"500px"}}>
    
   <div className="container">  
   <div style={{marginBottom:"10px"}}>
                 <button type="button" style={{marginRight:"10px"}} onClick={() => history.push('/gallery')} class="btn btn-warning">Go Back</button>
                
                 </div>
   <div style={{textAlign:'center'}}>
      <h2>{programName} (Year-{programYear})</h2>     
    </div>    
      <SimpleReactLightbox>
      <SRLWrapper>   
     
         <div>   
    <div className='row'>
        {
            content.map(
                (info,j)=>
                {
            return(
    <div className="col-md-3" >
          <div class="gallery-container" style={{marginTop:"30px"}}>           
         <img src={APIMEDIA+"/photogallery/"+info.file_url_name} />     
      </div>
      </div>
                
            )
                }
            )        
    }
      </div>
        
   </div>   

                  </SRLWrapper> 
                   </SimpleReactLightbox>
                  

</div>

 
       
  
        </section>
      
      
</main>
                </body>
                               
            )
    }

    return(
        <Base>
        {PhotoGalleryScreen()}
        </Base>
    )

}

export default Photos
import React,{useState,useEffect,useRef} from 'react'

import {Link,Redirect,useHistory} from "react-router-dom"
import Base from '../core/Base'
import backgroundOne from "../assets/images/bgHome.JPG";

import { Scrollbar as BaseScrollbar } from "smooth-scrollbar/scrollbar";
import Aos from 'aos'
import 'aos/dist/aos.css'
import { AnimationWrapper } from 'react-hover-animation'
import Fade from 'react-reveal/Fade';
   
import Carousel from 'react-bootstrap/Carousel'  
import man1 from "../assets/images/founders/man1.jpg";
import man2 from "../assets/images/founders/man2.jpg";
import man3 from "../assets/images/founders/man3.jpg";
import man4 from "../assets/images/founders/man4.jpg";

 import '../assets/css/style.css'
 import CountUp from 'react-countup';
 import { API } from '../backend'
 import axios from "axios";

const ContactUs=()=> {
  const [youtube_subscribers, setYoutubeSubscribes] = useState("");
  const [youtube_views, setYoutubeViews] = useState("");
  //const [NewsAndAnnoncement, setNews] = useState("No any news or annoncement found at this moment");
  const [allnews, setAllNews] = useState([]);
 
  const scrollbar = useRef<BaseScrollbar | null>(null);
  let history=useHistory();
  useEffect(() => {
   
    Aos.init({duration:2000})
      const unlisten = history.listen(() => {
        window.scrollTo(0, 0);
      });
      return () => {
        unlisten();
      }
    }, []);
    const ContactUsScreen=()=>{
        return(
         
          <body>
 

{/* <header class="masthead">
  <div class="container h-100">
    <div class="row h-100 align-items-center">
      <div class="col-12 text-center">
        <h1 class="fw-light">Contact Us</h1>
        
      </div>
    </div>
  </div>
</header> */}
 


                <main id="main" style={{marginTop:"100px",marginBottom:"400px"}}>
               
  
 <div className="container carousel-bottomsection-margin" style={{borderTop:"1px solid silver",borderBottom:"1px solid silver",marginTop:"50px"}}>


                <div className="row">
               
                <div className="col-lg-4" style={{borderRight:"1px solid silver",borderLeft:"1px solid silver",backgroundColor:'white'}}>
              
            
          
            <p style={{textAlign:'center',fontSize:"42px",color:"#1c5c93"}}><i class="fa fa-map-marker" aria-hidden="true"></i></p>
            <p style={{textAlign:'center',fontSize:"20px"}}>
             Address
            </p>
            <p style={{textAlign:'center',fontSize:"20px"}}>
            Smt.Putlaben Shah College of Education, Rajwada, Paga Chowk, Sangli, 416416 (Maharashtra)
            </p>
            
           
                </div>
                <div className="col-lg-4" style={{borderRight:"1px solid silver",backgroundColor:'white'}}>
              
            
             
              <p style={{textAlign:'center',fontSize:"42px",color:"#1c5c93"}}><i class="fa fa-phone " aria-hidden="true"></i></p>
            <p style={{textAlign:'center',fontSize:"20px"}}>
           Phone Number
            </p>
            <p style={{textAlign:'center',fontSize:"20px"}}>
            0233-2970178
            </p>
           
                  </div>
                  <div className="col-lg-4" style={{borderRight:"1px solid silver",backgroundColor:'white'}}>
              
            
             
              <p style={{textAlign:'center',fontSize:"42px",color:"#1c5c93"}}><i class="fa fa-envelope" aria-hidden="true"></i></p>
            <p style={{textAlign:'center',fontSize:"20px"}}>
            Email
            </p>
           
            <p style={{textAlign:'center',fontSize:"20px"}}>
            san_spsc@rediffmail.com
            </p>                        
                  </div>
                </div>
                </div>
 

  </main>
  
                </body>
               

                
            )
    }

    return(
        <Base>
        {ContactUsScreen()}
        </Base>
    )

}

export default ContactUs
import React,{useState,useEffect} from 'react'

import {Link,Redirect,useHistory} from "react-router-dom"
import Base from '../core/Base'

//import aboutphoto from "../assets/images/portfolio/02.jpg";
import Aos from 'aos'
import 'aos/dist/aos.css'




import Fade from 'react-reveal/Fade';

import Carousel from 'react-bootstrap/Carousel'  

const Objectives=()=> {
    let history=useHistory();
    useEffect(() => {
      Aos.init({duration:2000})
        const unlisten = history.listen(() => {
          window.scrollTo(0, 0);
        });
        return () => {
          unlisten();
        }
      }, []);
    const ObjectivesScreen=()=>{
        return(
          <body>
 
 <div>
 
 
   <section data-aos="fade-right" id="blog" className="blog" style={{marginTop:"100px"}}>
     
      <div className="container">
      <div className="col-lg-12">         
          <article class="entry entry-single" style={{backgroundColor:'#f9f9f9',textAlign:'justify'}}>
          <div class="page-hero d-flex align-items-center justify-content-center">
              <div> <h2 style={{color:"#000",fontSize:"19px",textAlign:"justify",fontWeight:"750"}}>OBJECTIVES</h2></div>
              </div>
      <p style={{color:"#000",fontSize:"20px",marginTop:"10px",fontWeight:"650"}}>
      <li>To make available competent, trained teachers for the secondary and higher secondary Schools in rural and urban areas run by the sanstha as well as other management.</li>
      </p>
      <p style={{color:"#000",fontSize:"20px",fontWeight:"650"}}>
      <li>To create competent and commitment oriented teachers.</li>
      </p>
      <p style={{color:"#000",fontSize:"20px",fontWeight:"650"}}>
      <li> To provide able, professional and skilled teachers to schools to attain desired social change.</li>
      </p>
      <p style={{color:"#000",fontSize:"20px",fontWeight:"650"}}>
     <li>To impart knowledge and training of latest technology for teaching.</li>
      </p>
      <p style={{color:"#000",fontSize:"20px",fontWeight:"650"}}>
      <li>To sensitize the would be teachers about emerging issues such as human rights, environmental awareness, population growth,gender equality, scientific temper and rational thinking.</li>
      </p>
      <p style={{color:"#000",fontSize:"20px",fontWeight:"650"}}>
      <li> To promte capabilities for inculcating national values and goals as enshrined in the constitution of India.</li>
      </p>
      <p style={{color:"#000",fontSize:"20px",fontWeight:"650"}}>
      <li> To educate the student teacher as an effective communicator, designer, facilitator and user of learning resources.</li>
      </p>
      <p style={{color:"#000",fontSize:"20px",fontWeight:"650"}}>
      <li> To enable the student teachers in experimentation and in using innovative practices and effective modes of curriculum transaction.</li>
      </p>
      <p style={{color:"#000",fontSize:"20px",fontWeight:"650"}}>
      <li> To stimulate social and national integration.</li>
      </p>
      <p style={{color:"#000",fontSize:"20px",fontWeight:"650"}}>
     <li> To provide in-service education to teachers, teacher educators and the inspecting staff concerned with primary, secondary and higher secondary education.</li>
      </p>

    
      </article>
          </div>
      </div>
     
    </section>
   
      
   
</div>
                </body>
               

                
            )
    }

    return(
        <Base>
        {ObjectivesScreen()}
        </Base>
    )

}

export default Objectives
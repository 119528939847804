import React,{useState,useEffect} from 'react'

import {Link,Redirect} from "react-router-dom"
import Base from '../core/Base'
import axios from "axios";
import { API } from '../backend'
const Course=()=> {
  const [content,setContent]=useState([])
  const getCourses = () => {    
    
    axios
    .get(
        `${API}/getcourses`,        
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      console.log(response);
      if(response.status===200)
        {
            if(response.data.status==true)
            {      
             
              console.log(response.data.courses)
            //   var iqacDetails= response.data.iqac
              setContent(response.data.courses);
            //   setSectionName(response.data.iqac[0].section_name)
            }
              
        }
        else
        {
            alert("error")
          
        }
    })
    .catch((error) => {
      console.log(error.message);
    });

    };
    useEffect(() => {
      getCourses();
     
    }, []);
    const courseScreen=()=>{
        return(
          <body>
                <main id="main">
             
    <section id="services" className="services" style={{marginTop:"100px"}}>
      <div className="container">

      
 <div class="section-title"> <h2 style={{color:"#FF0000",fontSize:"20px",textAlign:"justify"}}>Offered Courses</h2></div>
        <div className="row">
        <table class="table table-bordered">
              <thead>
             
              <th>Related Document</th>
                  <th>Course Name</th>
                  <th>University </th>
                  <th>Entry Qualification </th>
                  <th>Duration </th>
                  <th>Medium </th>
                  <th>Intake Capacity</th>
              </thead>
                <tbody>
            
        {
            content.map(
              (info) =>
              {
                var count = Object.keys(info).length;
                console.log(count);
                if(count>0)
                {
                   
                    if(info.file_url_name!='' && info.file_url_name!=undefined)
                    {
                return(
              
                      <tr>
                       
                          <td style={{color:'red'}}> <a href={'http://localhost:3000/filesData/'+info.file_url_name} target="_blank">  <i class="fa fa-file" aria-hidden="true"></i> </a> </td>
                          <td>{info.course_name}</td>
                          <td>{info.university}</td>
                          <td>{info.entry_qualification}</td>
                          <td>{info.duration}</td>
                          <td>{info.medium}</td>
                          <td>{info.intake_capacity}</td>
                          
                      </tr>
                     
                  )
                }
                else
                {
                    return(
              
                        <tr>
                          
                            <td style={{color:'red'}}> - </td>
                            <td>{info.course_name}</td>
                            <td>{info.university}</td>
                            <td>{info.entry_qualification}</td>
                            <td>{info.duration}</td>
                            <td>{info.medium}</td>
                            <td>{info.intake_capacity}</td>
                            
                        </tr>
                       
                    ) 
                }
                }
              
              }
            )
        }
                    
              {/* {preload} */}
                    
                </tbody>
            </table>
        {/* <table class="table table-bordered">
  <thead>
    <tr>
      <th scope="col">Courses</th>
      <th scope="col">Affiliated University</th>
      <th scope="col">Entry Qualification</th>
      <th scope="col">Duration	</th>
      <th scope="col">Medium of instruction</th>
      <th scope="col">Intake capacity</th>
   
    </tr>
  </thead>
  <tbody>
    <tr>
    
      <td> B.Ed(Regular)	</td>
      <td> Shivaji University, Kolhapur	</td>
      <td> Graduate	</td>
      <td> 2 years	</td>
      <td> Marathi	</td>
      <td> 50	</td>
     
    </tr>
  
    <tr>
    
    <td> B.Ed(Distance)		</td>
    <td>Yashwantrao Chavan Maharashtra Open University, Nasik	</td>
    <td> Graduation and In-service Teacher	</td>
    <td> 2 years	</td>
    <td> Marathi	</td>
    <td> 40	</td>
   
  </tr>
  
  </tbody>
</table> */}
          
        </div>

      </div>
    </section>

    

  
    <section id="clients" className="clients section-bg">
      <div className="container">

     
      </div>
    </section>

 
  
   
  <a href="#" id="toTop" class="back-to-top"><i class="icofont-simple-up"></i></a>

  </main>
                </body>
               

                
            )
    }

    return(
        <Base>
        {courseScreen()}
        </Base>
    )

}

export default Course
import React,{useState,useEffect} from 'react'

import {Link,Redirect} from "react-router-dom"
import Base from '../core/Base'
import { APIMEDIA } from '../backend'
import axios from "axios";
import { API } from '../backend'
const FeedbackForms=()=> {
    const [content,setContent]=useState([])
    
    const getFeedbackForms = () => {            
       
        axios
        .get(
            `${API}/getFeedbackForm`,
        
        )
        .then((response) => {
          console.log(response);
          if(response.status===200)
            {
                console.log("feedback form response",response)
                if(response.data.status==true)
                {      
                 
                   setContent(response.data.feedbackform);
                 
                }
                  
            }
            else
            {
                alert("error")
              
            }
        })
        .catch((error) => {
          console.log(error.message);
        });
  
        };


        useEffect(() => {
            getFeedbackForms();
          }, []);

    const FeedbackFormscreen=()=>{
        return(
          <body>
 
                <main id="main" style={{marginBottom:"400px"}}>
             
    <section id="services" className="services" style={{marginTop:"100px"}}>
      <div className="container">

      
 <div class="section-title"> <h2 style={{color:"#FF0000",fontSize:"20px",textAlign:"justify"}}>Feedback Forms</h2></div>
        <div className="row">
                              
                <table class="table table-bordered">
                <thead>
                    <tr style={{textAlign:"center"}}>
                        <th colSpan="3">Feedback Forms</th>                     
                    </tr>
                    </thead>
                    
                    {
         
         content.map(
           (info,j) =>
           {
               console.log("infoooo",info)
               return(
                        <tbody>
                        <tr>
                            <td>{j+1}</td>
                            <td style={{color:'red'}}> 
                         {/* <a href={APIMEDIA+"/filesdata/"+info.form_link} target="_blank">   */}
                         <a href={info.form_link} target="_blank">  

                         {info.form_caption} 
                         </a> 
                         </td>
                        <td>{info.form_year}</td>                
                    </tr>
                    </tbody>
               )
                  
           })
        }
                   
                    </table>
                   
                
           
                     
        
        
    
     
         
                  
             
         
          
        </div>

      </div>
    </section>

    

  
    <section id="clients" className="clients section-bg">
      <div className="container">

     
      </div>
    </section>

 
  
   
  <a href="#" id="toTop" class="back-to-top"><i class="icofont-simple-up"></i></a>

  </main>
                </body>
               

                
            )
    }

    return(
        <Base>
        {FeedbackFormscreen()}
        </Base>
    )

}

export default FeedbackForms
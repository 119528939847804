import React,{useState,useEffect} from 'react'

import {Link,Redirect} from "react-router-dom"
import Base from '../core/Base'
import { APIMEDIA } from '../backend'
import axios from "axios";
import { API } from '../backend'
const NAAC=()=> {
    const [content,setContent]=useState([])
    
    const getNAAC = () => {            
       
        axios
        .get(
            `${API}/getnaacSections`,
        
        )
        .then((response) => {
          console.log(response);
          if(response.status===200)
            {
                if(response.data.status==true)
                {      
                 
                   setContent(response.data.naac);
                 
                }
                  
            }
            else
            {
                alert("error")
              
            }
        })
        .catch((error) => {
          console.log(error.message);
        });
  
        };


        useEffect(() => {
            getNAAC();
          }, []);

    const NAACScreen=()=>{
        return(
          <body>
 
                <main id="main" style={{marginBottom:"400px"}}>
             
    <section id="services" className="services" style={{marginTop:"100px"}}>
      <div className="container">

      
 <div class="section-title"> <h2 style={{color:"#FF0000",fontSize:"20px",textAlign:"justify"}}>NAAC</h2></div>
        <div className="row">
         
      {
         
          content.map(
            (info,j) =>
            {
              
              console.log("Content info",info)           
              
               
          
              return(
                  
                <table class="table table-bordered">
                <thead>
                    <tr style={{textAlign:"center"}}>
                        <th colSpan="3">{info.section_name}</th>                     
                    </tr>
                    </thead>
                    {
                    info.files.sort((a, b) => parseFloat(b.yeartosort) - parseFloat(a.yeartosort)).map(
                  (filesMapped,k)=>{
                      console.log(filesMapped)
                      var srno=k+1;
                    return(
                        <tbody>
                       {
                        filesMapped.is_multi_files?
                        <>
                        <tr>
                        <td>{srno}</td>
                        <td>{filesMapped.file_caption}</td>                                           
                        </tr>
                        {
                          filesMapped.multi_files.map((multiFiles,index)=>{
                            const parts = multiFiles.originalname.split('.');
                            const trimmedFileName = parts.slice(0, -1).join('.');
                          
                            return(
                              <tr>
                              <td>
                               {srno}.{index+1}
                              </td>
                              <td colSpan="3">
                              <a href={APIMEDIA+'/filesdata/'+multiFiles.file_url_name} target="_blank">
                              <i class="fa fa-file" aria-hidden="true" style={{marginRight:"10px"}}></i> 
                              {
                             trimmedFileName
                              } 
                          </a>
                              </td>
                            </tr>
                            )

                          })
                         
                        }
                      
                        </>
                        :
                        <tr>
                            <td>{srno}</td>
                        <td><a href={APIMEDIA+'/filesdata/'+filesMapped.file_url_name} target="_blank">
                         
                        <i class="fa fa-file" aria-hidden="true" style={{marginRight:"10px"}}></i> {filesMapped.file_caption+""}
                          </a></td>                     
                        <td>{filesMapped.year}</td>                     
                    </tr>
                   
                    }
                    </tbody>
                    )
                }
                ) 
                }
                   
                   
                    </table>
                   
                ) 
           
                     
            }
          )
      }
     
         
                  
             
         
          
        </div>

      </div>
    </section>

    

  
    <section id="clients" className="clients section-bg">
      <div className="container">

     
      </div>
    </section>

 
  
   
  <a href="#" id="toTop" class="back-to-top"><i class="icofont-simple-up"></i></a>

  </main>
                </body>
               

                
            )
    }

    return(
        <Base>
        {NAACScreen()}
        </Base>
    )

}

export default NAAC
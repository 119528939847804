import React from 'react'
import Menu from './Menu'
import { Helmet } from 'react-helmet'
import {withRouter,Link} from "react-router-dom";

//import '../assets/vendor/jquery/jquery.min.js'

 const Base= ({
     
     title="",
     description="",
  
    className="text-black p-4",
     children
 }) =>  
     (
         
        <div>
           
        
           { <Menu/>}
            
              
            
     <div>{children}</div>
              
               <footer id="footer">
    {/* <div className="footer-top">
      <div className="container">
        <div className="row">

          <div className="col-lg-12 col-md-12">
            <div className="footer-info">
             
            
              <div className="social-links mt-3" style={{textAlign:'center',color:'white'}}>
               
                <a href="https://www.facebook.com/groups/165644352245616" target="_blank" className="facebook"><i className="bx bxl-facebook"></i></a>
                <a href="https://www.instagram.com/dr.sandeeppatil/" className="instagram" target="_blank"><i className="bx bxl-instagram"></i></a>
                <a href="https://www.linkedin.com/in/drsandeeppatil/?originalSubdomain=in" className="instagram" target="_blank"><i className="bx bxl-linkedin"></i></a>
                <a href="https://twitter.com/drsandeeppatils" className="instagram" target="_blank"><i className="bx bxl-twitter"></i></a>
              
              </div>
            </div>
          </div>

         

         
        

        </div>
      </div>
    </div> */}

    <div className="container" >
   
      <div className="copyright">
        &copy; Copyright <span>S.P.S. College of Education Sangli</span>. All Rights Reserved
      </div>
      <div className="credits">
     
         
        Visit Us On 0233-2970178 
      </div>
    </div>
  </footer>
  

               </div>
    )
     
 

  
export default Base
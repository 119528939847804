

import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import './index.css';

//import reportWebVitals from './reportWebVitals';
import Routes from './Routes'
import App from './App'
import { Helmet } from 'react-helmet'
//import 'bootstrap/dist/css/bootstrap.min.css';
// import './assets/vendor/icofont/icofont.min.css'
// import './assets/vendor/boxicons/css/boxicons.min.css'
// import './assets/vendor/animate.css/animate.min.css'
// import './assets/vendor/remixicon/remixicon.css'
// import './assets/vendor/venobox/venobox.css'
// import './assets/vendor/owl.carousel/assets/owl.carousel.min.css'
//  import './assets/css/style.css'

ReactDOM.render(
 <Routes/>,
  document.getElementById('root')
);

//ReactDOM.render(<Routes/>,document.getElementById("root"))
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

import React,{useState,useEffect} from 'react'

import {Link,Redirect,useHistory} from "react-router-dom"
import Base from '../core/Base'
import axios from "axios";
import { API } from '../backend'
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";
import { Formik, Form, Field, ErrorMessage} from "formik";
import { APIMEDIA } from '../backend'
import * as Yup from "yup";
const PhotoGallery=()=> {
    const [content, setAllContent] = useState([]);
    const [educationYears, setAllEducationalYears] = useState([]);
    const [year,setyear]=useState([])
    const getAllPhotos = () => {
      
        axios
        .get(
            `${API}/getAllPhotos`,              
        )
        .then((response) => {
          console.log(response);
          localStorage.setItem('gallery', JSON.stringify(response.data));
          if(response.status===200)
            {
                if(response.data.status==true)
                {                                   
                    setAllContent(response.data.photos)              
                }
                  
            }
    
        })
        .catch((error) => {
          console.log(error.message);
        });
  
        };
        const getAllYears = () => {
          axios
          .post(
              `${API}/getAllYears`,
            JSON.stringify({
            
              year_type:"Educational_Year"
            }),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log(response);
            if(response.status===200)
              {
                  if(response.data.status==true)
                  {      
                   
                    console.log(response.data.year)
                   
                    setAllEducationalYears(response.data.year);
                    
                  }
                    
              }
              else
              {
                  alert("error")
                
              }
          })
          .catch((error) => {
            console.log(error.message);
          });
        };

    let history=useHistory();
    useEffect(() => {
        getAllPhotos()
        getAllYears()
        const unlisten = history.listen(() => {
          window.scrollTo(0, 0);
        });
        return () => {
          unlisten();
        }
      }, []);
    const PhotoGalleryScreen=()=>{
      const initialValues = {
        
        year:year,
      
        
        };
        const validationSchema = Yup.object({                            
          year: Yup.string().required("Required"),                      
        });
      const onSubmit = (values, submitProps) => {
         console.log(values)
         axios
         .post(
             `${API}/getAllPhotosByYear`,
           JSON.stringify({
             values
            
           }),
           {
             headers: {
               "Content-Type": "application/json",
             },
           }
         )                
        .then((response) => {
            
          
          if(response.status===200)
            {
                if(response.data.status==true)
                {      
                   
                   setAllContent(response.data.photos)      
                }
                else
                {
                    submitProps.setSubmitting(false);
                 
                   alert(response.data.message)
                   
                }                 
            }
            else
            {
                alert("error")
              
            }
        })
        .catch((error) => {
        
          console.log(error.message);
        });
      
        };


        return(
          <body>
 
 <main id="main"> 
    <section id="blog" class="blog">
      <div className="container" style={{marginTop:"100px"}}>
   
                     <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form className="form-inline">  
 <div className="row">
                 <div className="col-md-6">
                  <div className="form-group">
                              
                      <Field name="year" id="year">
                                    {({ field }) => (
                                        <select {...field} className="form-control" >
                                            <option value="N/A">Select Year</option>
                                            {educationYears.map(i => 
                                                <option key={i.id} value={i.year}>{i.year}</option>
                                            )}
                                        </select>
                                    )}
                                    </Field>

                 
                  
                  </div>  
                  </div>
                      <div className="col-md-3">
                      <div className="form-group">
                      <button
                    type="submit"
                    className="btn btn-success"
                  >                                      
                    Search
                  </button>
                  </div>
                  </div>
                  </div>
                  
                
                     
                 
            </Form>
          );
        }}
      </Formik>
     
      </div>
   <div className="container">  
            
      <SimpleReactLightbox>
      <SRLWrapper>   
     
{
  
  content.sort((a, b) => parseFloat(b.year) - parseFloat(a.year)).map(
    (info,j)=>
    {
      
        console.log("info",info)
        return(
          <div>   
                   
{
    info.program.map((Allfiles,k)=>
    {
       return(
         <div style={{marginTop:"20px"}}>
           
  <div style={{textAlign:'left'}}>
  <table class="table">
  
  <tbody>
    <tr>
      
      <td>  <Link to={"/photos/GalleryId="+info.gallery_id+"&ProgramId="+Allfiles.program_id}> {Allfiles.program_name} (Year-{info.year}) </Link></td>
     
    </tr>
  
  </tbody>
</table>


      {/* <h2>{Allfiles.program_name} (Year-{info.year})</h2>      */}
    </div>
    
   
         </div>
       )
    }
    )
}

            </div>
                                           
           )
       
    }
)
    }


                  </SRLWrapper> 
                   </SimpleReactLightbox>
                  

</div>

 
       
  
        </section>
      
      
</main>
                </body>
               

                
            )
    }

    return(
        <Base>
        {PhotoGalleryScreen()}
        </Base>
    )

}

export default PhotoGallery